import {
  AcademicCapIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  Cog8ToothIcon,
  DocumentTextIcon,
  FolderIcon,
  HomeIcon,
  MegaphoneIcon,
  PlusCircleIcon,
  RectangleStackIcon,
  SparklesIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
  ViewColumnsIcon
} from '@heroicons/react/24/outline'
import {
  BriefcaseIcon as BriefcaseIconSolid,
  ChatBubbleLeftEllipsisIcon as ChatBubbleLeftEllipsisIconSolid,
  Cog8ToothIcon as Cog8ToothIconSolid,
  MegaphoneIcon as MegaPhoneIconSolid,
  SparklesIcon as SparklesIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  VideoCameraIcon as VideoCameraIconSolid,
  ViewColumnsIcon as ViewColumnsIconSolid
} from '@heroicons/react/24/solid'
import {
  KNOWLEDGE_BASE_URL,
  KNOWLEDGE_BASE_URL_V2
} from '@qureos/statics/knowledge-base'
import { sideMenuMap } from '@qureos/statics/menu'
import { UserRole } from 'src/types/shared/graphql'
import { PageInterface, SidebarSectionsEnum } from 'statics/interface'
import { matchUrlPath } from './route'

const { Admin, Student, Mentor, Enterprise, Professional } = UserRole

export const isActive = (routerPathname: string, pagePathname, user) => {
  if (user?.role === UserRole.Professional) {
    return (
      sideMenuMap[routerPathname] &&
      matchUrlPath(sideMenuMap[routerPathname], pagePathname)
    )
  } else {
    let selectedPathIsActive = false
    Object.keys(sideMenuMap).forEach(key => {
      if (routerPathname.includes(key)) {
        selectedPathIsActive = sideMenuMap[key] === pagePathname
      }
    })
    return selectedPathIsActive
  }
}

export const MENU = (
  isVfsServiceEnable: boolean,
  activeWorkspaceId?: string
): PageInterface[] => [
  {
    icon: {
      active: <HomeIcon className="w-8 h-7" />,
      normal: <HomeIcon className="w-8 h-7" />,
      activeMobile: <HomeIcon className="w-5" />,
      normalMobile: <HomeIcon className="w-5" />
    },
    label: 'Dashboard',
    pathname: 'dashboard',
    // permissions: allRoles,
    permissions: [
      UserRole.Admin,
      UserRole.Mentor,
      UserRole.Enterprise,
      ...(!isVfsServiceEnable ? [UserRole.Professional] : [])
    ].filter(Boolean),
    restriction: [''],
    //by sign up code
    url: isVfsServiceEnable ? '/vfs/job-description-generator' : '/home',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <UsersIcon className="w-8 h-7" />,
      normal: <UsersIcon className="w-8 h-7" />,
      activeMobile: <UsersIcon className="w-6 h-7" />,
      normalMobile: <UsersIcon className="w-6 h-7 " />
    },
    label: 'Events',
    pathname: '/cohort-management/event',
    permissions: [Mentor],
    restriction: [],
    url: '/cohort-management/event',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <UsersIcon className="w-8 h-7" />,
      normal: <UsersIcon className="w-8 h-7 " />,
      activeMobile: <UsersIcon className="w-6 h-7" />,
      normalMobile: <UsersIcon className="w-6 h-7 " />
    },
    label: 'Learning Hub',
    pathname: 'learning-hub',
    permissions: [Student],
    restriction: ['aeic', 'dpw'],
    //by sign up code
    url: '/learning-hub',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <HomeIcon className="w-8 h-7" />,
      normal: <HomeIcon className="w-8 h-7 " />,
      activeMobile: <HomeIcon className="w-6 h-7" />,
      normalMobile: <HomeIcon className="w-6 h-7 " />
    },
    label: 'For You',
    pathname: 'for-you',
    permissions: [Student],
    restriction: ['for-you'],
    //by sign up code
    url: '/apprentice-dashboard',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <UserGroupIcon className="w-8" />,
      normal: <UserGroupIcon className="w-8" />,
      activeMobile: <UserGroupIcon className="w-6" />,
      normalMobile: <UserGroupIcon className="w-6" />
    },
    label: 'Mentorship',
    pathname: 'mentor',
    permissions: [Student],
    restriction: [''],
    url: '/mentorship?mentorship=true',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <RectangleStackIcon className="w-8 h-7" />,
      normal: <RectangleStackIcon className="w-8 h-7" />,
      activeMobile: <RectangleStackIcon className="w-6 h-7" />,
      normalMobile: <RectangleStackIcon className="w-6 h-7" />
    },
    label: 'Projects',
    pathname: 'projects',
    permissions: [Admin, Enterprise, Student],
    restriction: [''],
    //by sign up code
    url: '/projects',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <BriefcaseIcon className="w-8 h-7" />,
      normal: <BriefcaseIcon className="w-8 h-7" />,
      activeMobile: <BriefcaseIcon className="w-6 h-7" />,
      normalMobile: <BriefcaseIcon className="w-6 h-7" />
    },
    label: 'Jobs',
    pathname: 'jobs',
    permissions: [Student],
    restriction: [''],
    url: '/jobs',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <UsersIcon className="w-8 h-7" />,
      normal: <UsersIcon className="w-8 h-7" />,
      activeMobile: <UsersIcon className="w-6 h-7" />,
      normalMobile: <UsersIcon className="w-6 h-7" />
    },
    label: 'Community',
    pathname: 'community',
    permissions: [Student],
    restriction: ['dpw'],
    //by sign up code
    url: '/community',
    link: 'https://discord.gg/MWFdJzMJCD',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <RectangleStackIcon className="w-8 h-7" />,
      normal: <RectangleStackIcon className="w-8 h-7" />
    },
    label: 'Workspace',
    pathname: 'workspace',
    permissions: [Enterprise],
    restriction: [''],
    //by sign up code
    url: '/workspace',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <ClockIcon className="w-8 h-7" />,
      normal: <ClockIcon className="w-8 h-7" />,
      activeMobile: <ClockIcon className="w-6 h-7" />,
      normalMobile: <ClockIcon className="w-6 h-7" />
    },
    label: 'Bookings',
    pathname: 'bookings',
    permissions: [Mentor],
    restriction: [''],
    url: '/bookings',
    disabled: false,
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <CalendarIcon className="w-8 h-7" />,
      normal: <CalendarIcon className="w-8 h-7" />,
      activeMobile: <CalendarIcon className="w-8 h-7" />,
      normalMobile: <CalendarIcon className="w-8 h-7" />
    },
    label: 'Calendar',
    pathname: 'calendar',
    permissions: [Mentor],
    restriction: [],
    url: '/calendar',
    tooltipText: 'Calendar',
    disabled: false,
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <BuildingOfficeIcon className="w-8" />,
      normal: <BuildingOfficeIcon className="w-8" />,
      activeMobile: <BuildingOfficeIcon className="w-5" />,
      normalMobile: <BuildingOfficeIcon className="w-5" />
    },
    label: 'Company',
    pathname: 'company',
    permissions: !isVfsServiceEnable ? [Professional] : [],
    restriction: [''],
    url: '/company',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <FolderIcon className="w-8" />,
      normal: <FolderIcon className="w-8" />
    },
    label: 'Project',
    pathname: 'projects',
    permissions: [],
    restriction: [''],
    url: '/projects',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <BriefcaseIcon className="w-8" />,
      normal: <BriefcaseIcon className="w-8" />,
      normalMobile: <BriefcaseIcon className="w-5" />,
      activeMobile: <BriefcaseIcon className="w-5" />
    },
    label: 'Jobs',
    pathname: 'corporate/jobs',
    permissions: !isVfsServiceEnable ? [Professional] : [],
    restriction: [''],
    url: '/corporate/jobs',
    showNotificationBadge: true,
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <UsersIcon className="w-8" />,
      normal: <UsersIcon className="w-8" />,
      normalMobile: <UsersIcon className="w-5" />,
      activeMobile: <UsersIcon className="w-5" />
    },
    label: 'Talent Pool',
    pathname: 'corporate/talent-pool',
    permissions: !isVfsServiceEnable ? [Professional] : [],
    restriction: [''],
    url: '/corporate/talent-pool',
    showNotificationBadge: false,
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <SquaresPlusIcon className="w-8" />,
      normal: <SquaresPlusIcon className="w-8" />,
      normalMobile: <SquaresPlusIcon className="w-5" />,
      activeMobile: <SquaresPlusIcon className="w-5" />
    },
    label: 'Integrations',
    pathname: 'corporate/integrations',
    permissions: !isVfsServiceEnable ? [Professional] : [],
    restriction: [''],
    url: '/corporate/integrations',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <BriefcaseIcon className="w-8" />,
      normal: <BriefcaseIcon className="w-8" />,
      normalMobile: <BriefcaseIcon className="w-5" />,
      activeMobile: <BriefcaseIcon className="w-5" />
    },
    label: 'JD Generator',
    pathname: 'vfs/job-description-generator',
    permissions: [Professional],
    restriction: ['vfs-service'],
    url: '/vfs/job-description-generator',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <DocumentTextIcon className="w-8" />,
      normal: <DocumentTextIcon className="w-8" />,
      normalMobile: <DocumentTextIcon className="w-5" />,
      activeMobile: <DocumentTextIcon className="w-5" />
    },
    label: 'Report',
    pathname: 'vfs/reports',
    permissions: [Professional],
    restriction: ['vfs-service'],
    url: '/vfs/reports',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <PlusCircleIcon className="w-8" />,
      normal: <PlusCircleIcon className="w-8" />
    },
    label: 'Create a project',
    pathname: 'create-project',
    permissions: [],
    restriction: [''],
    url: '/create-project',
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <AcademicCapIcon className="w-8 h-7" />,
      normal: <AcademicCapIcon className="w-8 h-7" />,
      activeMobile: <AcademicCapIcon className="w-6 h-7" />,
      normalMobile: <AcademicCapIcon className="w-6 h-7" />
    },
    label: 'Knowledge Base',
    pathname: 'knowledge-base',
    permissions: [Professional],
    restriction: [],
    url: '',
    link: KNOWLEDGE_BASE_URL,
    section: SidebarSectionsEnum.OTHER_SECTION
  },
  {
    icon: {
      active: <SparklesIconSolid className="w-5 h-5 mx-auto" />,
      normal: <SparklesIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <SparklesIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <SparklesIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Sourcing',
    pathname: `/corporate-v2/workspace/${activeWorkspaceId}/source`,
    permissions: [Professional],
    restriction: [''],
    url: `/corporate-v2/workspace/${activeWorkspaceId}/source`,
    section: SidebarSectionsEnum.PRIMARY_SECTION
  },
  {
    icon: {
      active: <BriefcaseIconSolid className="w-6 h-6 mx-auto" />,
      normal: <BriefcaseIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <BriefcaseIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <BriefcaseIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Job',
    pathname: activeWorkspaceId
      ? `/corporate-v2/workspace/${activeWorkspaceId}/jobs`
      : '/corporate/jobs',
    permissions: [Professional],
    restriction: [''],
    url: activeWorkspaceId
      ? `/corporate-v2/workspace/${activeWorkspaceId}/jobs`
      : '/corporate/jobs',
    section: SidebarSectionsEnum.PRIMARY_SECTION,
    dataCy: 'sidebar-jobs-link'
  },
  // {
  //   icon: {
  //     active: <PlusIcon className="w-6 h-6 mx-auto" />,
  //     normal: <PlusIcon className="w-6 h-6 mx-auto" />,
  //     activeMobile: <PlusIcon className="w-6 h-6 mx-auto" />,
  //     normalMobile: <PlusIcon className="w-6 h-6 mx-auto" />
  //   },
  //   label: 'Add New',
  //   pathname: 'add-new',
  //   permissions: [Professional],
  //   restriction: [''],
  //   url: '',
  //   section: SidebarSectionsEnum.PRIMARY_SECTION
  // },
  {
    icon: {
      active: <ViewColumnsIconSolid className="w-6 h-6 mx-auto" />,
      normal: <ViewColumnsIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <ViewColumnsIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <ViewColumnsIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Workspace',
    pathname: 'corporate-v2/workspace/workspaceId/source',
    permissions: [Professional],
    restriction: [''],
    url: '/corporate-v2/workspace',
    section: SidebarSectionsEnum.SECONDARY_SECTION
  },
  {
    icon: {
      active: <UserGroupIconSolid className="w-6 h-6 mx-auto" />,
      normal: <UserGroupIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <UserGroupIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <UserGroupIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Talent Pool',
    pathname: 'corporate/talent-pool',
    permissions: [Professional],
    restriction: [''],
    url: '/corporate/talent-pool',
    showNotificationBadge: false,
    section: SidebarSectionsEnum.SECONDARY_SECTION
  },
  {
    icon: {
      active: <MegaPhoneIconSolid className="w-6 h-6 mx-auto" />,
      normal: <MegaphoneIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <MegaPhoneIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <MegaphoneIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Campaign',
    pathname: 'corporate-v2/campaign',
    permissions: [Professional],
    restriction: [''],
    url: '/corporate-v2/campaign',
    section: SidebarSectionsEnum.SECONDARY_SECTION
  },
  // {
  //   icon: {
  //     active: <EnvelopeIconSolid className="w-6 h-6 mx-auto" />,
  //     normal: <EnvelopeIcon className="w-6 h-6 mx-auto" />,
  //     activeMobile: <EnvelopeIconSolid className="w-6 h-6 mx-auto" />,
  //     normalMobile: <EnvelopeIcon className="w-6 h-6 mx-auto" />
  //   },
  //   label: 'Message',
  //   pathname: 'message',
  //   permissions: [Professional],
  //   restriction: [''],
  //   url: '',
  //   section: SidebarSectionsEnum.SECONDARY_SECTION
  // },
  {
    icon: {
      active: <VideoCameraIconSolid className="w-6 h-6 mx-auto" />,
      normal: <VideoCameraIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <VideoCameraIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <VideoCameraIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Video Assessments',
    pathname: 'corporate-v2/video-assessments',
    permissions: [Professional],
    restriction: [''],
    url: '/corporate-v2/video-assessments',
    section: SidebarSectionsEnum.SECONDARY_SECTION
  },
  // {
  //   icon: {
  //     active: <InferredJobIcon className="w-6 h-6 mx-auto" />,
  //     normal: <InferredJobIcon className="w-6 h-6 mx-auto" />,
  //     activeMobile: <InferredJobIcon className="w-6 h-6 mx-auto" />,
  //     normalMobile: <InferredJobIcon className="w-6 h-6 mx-auto" />
  //   },
  //   label: 'Job Description',
  //   pathname: 'job-description',
  //   permissions: [Professional],
  //   restriction: [''],
  //   url: '',
  //   section: SidebarSectionsEnum.SECONDARY_SECTION
  // },
  {
    icon: {
      active: <Cog8ToothIconSolid className="w-6 h-6 mx-auto" />,
      normal: <Cog8ToothIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <Cog8ToothIconSolid className="w-6 h-6 mx-auto" />,
      normalMobile: <Cog8ToothIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Configuration',
    pathname: 'corporate-v2/configuration',
    permissions: [Professional],
    restriction: [''],
    url: '/corporate-v2/configuration',
    section: SidebarSectionsEnum.UTILITY_SECTION,
    dataCy: 'sidebar-configuration-link'
  },
  // {
  //   icon: {
  //     active: <RocketLaunchIconSolid className="w-6 h-6 mx-auto" />,
  //     normal: <RocketLaunchIcon className="w-6 h-6 mx-auto" />,
  //     activeMobile: <RocketLaunchIconSolid className="w-6 h-6 mx-auto" />,
  //     normalMobile: <RocketLaunchIcon className="w-6 h-6 mx-auto" />
  //   },
  //   label: 'Upgrade Plan',
  //   pathname: 'upgrade',
  //   permissions: [Professional],
  //   restriction: [''],
  //   url: '',
  //   section: SidebarSectionsEnum.UTILITY_SECTION
  // },
  {
    icon: {
      active: <ChatBubbleLeftEllipsisIconSolid className="w-6 h-6 mx-auto" />,
      normal: <ChatBubbleLeftEllipsisIcon className="w-6 h-6 mx-auto" />,
      activeMobile: (
        <ChatBubbleLeftEllipsisIconSolid className="w-6 h-6 mx-auto" />
      ),
      normalMobile: <ChatBubbleLeftEllipsisIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Talk to Sales',
    pathname: 'talk-to-sales',
    permissions: [Professional],
    restriction: [''],
    url: '#',
    section: SidebarSectionsEnum.UTILITY_SECTION
  },
  {
    icon: {
      active: <AcademicCapIcon className="w-6 h-6 mx-auto" />,
      normal: <AcademicCapIcon className="w-6 h-6 mx-auto" />,
      activeMobile: <AcademicCapIcon className="w-6 h-6 mx-auto" />,
      normalMobile: <AcademicCapIcon className="w-6 h-6 mx-auto" />
    },
    label: 'Knowledge Base',
    pathname: 'knowledge-base',
    permissions: [Professional],
    restriction: [''],
    url: '',
    link: KNOWLEDGE_BASE_URL_V2,
    section: SidebarSectionsEnum.UTILITY_SECTION
  }
]
