export const KNOWLEDGE_BASE_URL =
  'https://qureos.notion.site/Welcome-to-Iris-Knowledge-Base-d19af18bd32b468f87d65f791c01b538'

export const KNOWLEDGE_BASE_URL_V2 =
  'https://www.qureos.com/iris-knowledge-base'

export const KNOWLEDGE_BASE_AI_SCREENING_URL =
  'https://qureos.notion.site/AI-Screening-11a20f6a72ff808f8969c446927ad040'

export const HOW_TO_SOURCE_LINK =
  'https://qureos.notion.site/How-to-Source-6738e219861149e3a12e26366d2c2149'
