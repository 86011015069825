import { User, UserRole } from 'src/types/shared/graphql'

export interface PageIconInterface {
  active: any
  normal: any
  activeMobile?: any
  normalMobile?: any
}

export interface PageInterface {
  icon: PageIconInterface
  label: string
  pathname: string
  restriction: string[]
  url: string
  permissions?: UserRole[]
  disabled?: boolean
  tooltipText?: string
  link?: string
  showNotificationBadge?: boolean
  section?: string
  dataCy?: string
}

export interface SidebarProps {
  showLabel?: boolean
  role?: UserRole
  signupCode?: string
  user?: User
  isMobile?: boolean
  isDrawerOpen?: boolean
}

export interface QuestionInterface {
  _id?: number
  created_at?: string
  body: string
  competence?: string
}

export interface aiGeneratedQuestionProps {
  _id?: string
  title: string
  questions: QuestionInterface[] | QuestionInterface
  slug?: string
  company_id?: string
  created_at?: string
}

export interface aiInterviewQuestionsProps extends aiGeneratedQuestionProps {
  id: string
  questions: QuestionInterface
  competency?: string
  criteria?: string[]
  duration?: string
  retakes?: string
  isOptional: boolean
}

export interface candidatesAiInterviewProps {
  _id: string
  question: string
  answer: string
  answerCriteriaMatch: number
  videoUrl: string
  aiAnswerFeedback: {
    pros: string
    cons: string
  }
  scores: {
    overAll: number
    cvMatching: number
    aiPersonality: number
  }
  behaviorScores: {
    extraversion: number
    neuroticism: number
    agreeableness: number
    conscientiousness: number
    openness: number
  }
  feelingScores: {
    happy: number
    calm: number
    confused: number
    scared: number
  }
}

export enum weightageEnum {
  cvMatching = 'cvMatchingWeight',
  answerMatching = 'answerMatchingWeight',
  personalityMatch = 'personalityMatchingWeight'
}

export interface IWeightageProps {
  icon: any
  title: string
  defaultValue: number
  finalValue: number
  slug: weightageEnum
}

export enum SidebarSectionsEnum {
  PRIMARY_SECTION = 'primary-section',
  SECONDARY_SECTION = 'secondary-section',
  UTILITY_SECTION = 'utility-section',
  OTHER_SECTION = 'other-section'
}
